<template>
    <DetailItemEditCard ref="detailItemEditCard" v-bind="$attrs" v-on="$listeners" item-name="facturen"
        :promises.sync="detailItemEditCardPromises" :formPromises.sync="computedPromises"
        :detail-view-route-location="{ name: routeNames.INVOICE_OVERVIEW }">
        <template v-slot:after-header>
            <slot name="after-header"/>
        </template>
        <template v-slot:form-fields>
            <slot name="form-fields" />
        </template>
        <template v-slot:buttons-left="slotProps">
            <slot name="buttons-left" v-bind="slotProps"/>
        </template>
        <template v-slot:save-button-content>
            <slot name="save-button-content">
                Genereer
            </slot>
        </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";

export default {
    name: "InvoiceEditCard",
    props: {
        invoiceId: {
            type: String,
            required: false
        },
        promises: {
            default: () => []
        }
    },
    emits: ["update:invoiceEditCardPromises"],
    data() {
        return {
            invoiceName: null,
            routeNames,
            detailItemEditCardPromises: [this.fetchInvoiceName()]
        }
    },
    inheritAttrs: false,
    components: {
        DetailItemEditCard,
    },
    computed: {
        computedPromises: {
            get() {
                return this.promises
            },
            set(newValue) {
                this.$emit("update:invoiceEditCardPromises", newValue)
            },
        },
    },
    methods: {
        fetchInvoiceName() {
            if (this.invoiceId) {
                return this.$store.dispatch('invoicesModule/fetchInvoiceName', { invoiceId: this.invoiceId })
                    .then((result) => this.invoiceName = result)
            }
            return null
        }
    }
}
</script>