<template>
    <v-overlay :value="shouldShow" :z-index="1001">
        <v-btn color="$fossil-gray"
                absolute
                dark
                top
                right
                class="mt-9"
                small
                depressed
                fab
                @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="overflow-auto">
          <slot></slot>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: "Overlay",
    model: {
        prop: 'value',
        event: 'update'
    },
    data() {
      return {
        show: false
      }
    },
    props: {
      value: {
        type: Boolean,
        default: null,
        required: false
      },
    },
    methods: {
      toggle() {
        if (this.show) {
          this.close()
        } else {
          this.show = true
        }
      },
      close() {
        this.show = false
      }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update', newValue)
            }
        },
        shouldShow() {
          if (this.modelValue === null) {
            return this.show
          } else {
            return this.modelValue
          }
        }
    }
}
</script>