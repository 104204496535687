<template>
    <div>
        <InvoiceEditCard :title="getTitle" hide-save-button :promises.sync="promises" :invoiceId="invoiceId">
            <template v-slot:after-header>
                <v-col cols="auto" class="flex-grow-0">
                    <v-chip :color="getInvoiceStatusTypeColor(invoiceDTO.statusType)" label large class="max-h-36px">
                        {{ translateInvoiceStatusType(invoiceDTO.statusType) }}
                    </v-chip>
                </v-col>
                <v-col cols="auto" class="flex-grow-0">
                    <v-menu offset-y left bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined class="text-none" color="secondary" v-bind="attrs" v-on="on">
                            Acties
                            <v-icon left class="ma-0">mdi-chevron-down</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-if="showTogglePaidButton" link @click="onTogglePaidClick">
                                <v-list-item-icon>
                                    <v-icon>{{ togglePaidIcon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ togglePaidText }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="showCreditNoteButton" link @click="onCreditNoteClick" >
                                <v-list-item-icon>
                                    <v-icon>mdi-invoice-text-minus-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Creditnota</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="showInvoiceReferenceButton" link @click="onInvoiceReferenceClick" >
                                <v-list-item-icon>
                                    <v-icon>mdi-link-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Gelinkte {{ invoiceDTO?.credit ? 'factuur' : 'creditnota' }}: {{ invoiceDTO?.invoiceReference?.invoiceNumber }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="showDeleteButton" link @click="onDeleteClientClick">
                                <v-list-item-icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Verwijder</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </template>
            <template v-slot:form-fields>
                <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-invoice-edit-outline</v-icon>
                    Algemeen</v-subheader>
                <v-subheader class="text-h6 pl-0"><v-icon class="pr-2">mdi-account-box</v-icon>Klant</v-subheader>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Klant" type="text"
                            v-model="invoiceDTO.organizationToName"></v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Klant btw nummer" type="text" v-model="invoiceDTO.organizationToVat"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <AddressInput v-model="invoiceDTO.organizationToAddress" readonly/>
                    </v-col>
                </v-row>
                <v-subheader class="text-h6 pl-0"><v-icon class="pr-2">mdi-invoice-text-outline</v-icon>Factuur</v-subheader>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Factuurnummer" type="text"
                            v-model="invoiceDTO.invoiceNumber"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Datum" :value="applyDateFormat(invoiceDTO.invoiceDate)" />
                    </v-col>
                    <v-col v-if="showExpirationTime" cols="12" md="6">
                        <v-text-field readonly label="Betalingstermijn (dagen)" type="text"
                            v-model="invoiceDTO.daysToPay"></v-text-field>
                    </v-col>
                    <v-col v-if="showExpirationTime" cols="12" md="6">
                        <v-text-field readonly label="Vervaldatum"
                            :value="applyDateFormat(invoiceDTO.expirationDate)" />
                    </v-col>
                </v-row>
                <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-invoice-text-clock-outline</v-icon>
                    Berekening</v-subheader>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Begin facturatieperiode"
                            :value="applyDateFormat(invoiceDTO.invoicePeriodFrom)" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field readonly label="Einde facturatieperiode"
                            :value="applyDateFormat(invoiceDTO.invoicePeriodUntil)" />
                    </v-col>
                </v-row>
                <v-row v-for="(clientInvoiceLine, index) in invoiceDTO.clientInvoiceLines" :key="index">
                    <v-col cols="12" md="4" lg="2" v-if="isInvoiceLineWorkingHours( clientInvoiceLine.type)">
                        <v-text-field readonly label="Personeelslid" :value="clientInvoiceLine.userName" />
                    </v-col>
                    <v-col cols="12" md="8" lg="2" v-if="isInvoiceLineWorkingHours( clientInvoiceLine.type)">
                        <v-text-field readonly label="Klantnaam" :value="clientInvoiceLine.clientName" />
                    </v-col>
                    <v-col cols="12" md="8" lg="2" v-if="isInvoiceLineWorkingHours( clientInvoiceLine.type)">
                        <v-text-field readonly label="Projectnaam" :value="clientInvoiceLine.projectName" />
                    </v-col>
                    <v-col cols="12" md="8" lg="6" v-if="isInvoiceLineDescription( clientInvoiceLine.type)">
                        <v-text-field readonly label="Omschrijving" :value="clientInvoiceLine.description" />
                    </v-col>
                    <v-col cols="12" md="2" lg="1">
                        <v-text-field readonly label="BTW (%)" type="text" v-model="clientInvoiceLine.vat" />
                    </v-col>
                    <v-col cols="12" md="3" lg="1" v-if="isInvoiceLineWorkingHours( clientInvoiceLine.type)">
                        <v-text-field readonly label="Bedrag per uur (€)" type="text" 
                            v-model="clientInvoiceLine.priceByHour"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" v-if="isInvoiceLineWorkingHours( clientInvoiceLine.type)">
                        <v-text-field readonly label="Gefactureerde uren" type="text" 
                            :value="getFormatedRegisteredHours(clientInvoiceLine.reportedMinutes)"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" lg="1" v-if="isInvoiceLineDescription( clientInvoiceLine.type)">
                        <v-text-field readonly label="Eenheidsprijs (€)" type="text" 
                            v-model="clientInvoiceLine.priceByHour"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" v-if="isInvoiceLineDescription( clientInvoiceLine.type)">
                        <v-text-field readonly label="Aantal" type="text" 
                            :value="getFormatedRegisteredHours(clientInvoiceLine.reportedMinutes)"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" lg="2">
                        <v-text-field readonly label="Gefactureerd bedrag" reverse
                            :value="getFomratedTotal(clientInvoiceLine.total)"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="invoiceDTO.blobStorageUrl && getSASKey" cols="12" md="6">
                        <v-sheet flat outlined>
                            <v-toolbar flat>
                                <v-btn icon @click="$refs.overlay.toggle()">
                                    <v-icon>mdi-text-box-search-outline</v-icon>
                                </v-btn>
                                <v-btn icon @click="$refs.pdf.download(invoiceDTO.invoiceNumber)">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <vue-pdf-embed ref="pdf" :source="getPDfSource" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </template>
        </InvoiceEditCard>
        <Overlay ref="overlay">
            <vue-pdf-embed class="max-h-100vh max-w-100vw" :source="getPDfSource" />
        </Overlay>
    </div>
</template>
<script>
import InvoiceEditCard from "@/views/invoice/InvoiceEditCard.vue";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';
import { applyDateFormat } from "@/shared/utils/dateUtils";
import { formatDuration } from "date-fns";
import { nlBE } from "date-fns/locale";
import { splitMinutesToDuration } from "@/shared/utils/dateUtils";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import Overlay from "@/components/shared/overlays/Overlay.vue";
import InvoiceLineType from "@/shared/enums/invoiceLineType";
import AddressInput from "@/components/shared/fields/AddressInput.vue";
import { translateInvoiceStatusType, getInvoiceStatusTypeColor } from "@/shared/utils/translateUtils";
import RouteNames from "@/router/RouteNames";
import InvoiceStatusType from "@/shared/enums/invoiceStatusType";



export default {
    name: "InvoiceDetail",
    props: {
        invoiceId: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            invoiceDTO: {
                name: "factuur",
                clientId: null,
                invoiceNumber: null,
                invoiceDate: null,
                daysToPay: null,
                invoicePeriodFrom: null,
                invoicePeriodUntil: null,
                organizationToAddress: {},
                organizationToVat: null
            },
            InvoiceLineType: InvoiceLineType,
            sasKey: null,
            promises: [
                this.fetchInvoiceInformation(),
                this.$store.dispatch("fileExportModule/fetchSASKey").then(result => this.sasKey = result)
            ]
        }
    },
    methods: {
        translateInvoiceStatusType,
        getInvoiceStatusTypeColor,
        getRequiredRules,
        applyDateFormat,
        fetchInvoiceInformation() {
            return this.$store.dispatch("invoicesModule/fetchInvoiceInformation", { invoiceId: this.invoiceId })
                .then(result => {
                    this.invoiceDTO = {
                        ...result,
                    }
                })
        },
        getFormatedRegisteredHours(reportedMinutes) {
            // Allow for the number 0 to be displayed
            if (reportedMinutes === null || reportedMinutes === undefined) {
                return null
            }
            return formatDuration(splitMinutesToDuration(reportedMinutes), { locale: nlBE, zero: false })
        },
        getFomratedTotal(total) {
            // Allow for the number 0 to be displayed
            if (total === null || total === undefined) {
                return null
            }
            return `€${total.toFixed(2)}`
        },
        isInvoiceLineWorkingHours(type){
            return type === InvoiceLineType.WORKING_HOURS
        },
        isInvoiceLineDescription(type){
            return type === InvoiceLineType.DESCRIPTION
        },
        onDeleteClientClick() {
            this.$store.dispatch("invoicesModule/deleteInvoice", this.invoiceId)
                .then(() => this.$router.push({name: RouteNames.INVOICE_OVERVIEW}))
        },
        onTogglePaidClick() {
            this.promises.push(this.$store.dispatch("invoicesModule/togglePaid", this.invoiceId)
                .then(() => this.promises.push(this.fetchInvoiceInformation())))
        },
        onCreditNoteClick() {
            this.$router.push({name: RouteNames.INVOICE_CREATE, query: {credit: true, originalInvoiceId: this.invoiceId}})
        },
        onInvoiceReferenceClick() {
            this.$router.push({name: RouteNames.INVOICE_DETAIL, params: {invoiceId: this.invoiceDTO?.invoiceReference?.invoiceId}})
        }
    },
    components: {
        InvoiceEditCard,
        VuePdfEmbed,
        Overlay,
        AddressInput
    },
    computed: {
        getTitle() {
            if (this.invoiceDTO?.credit) {
                return "Creditnota informatie"
            } else {
                return "Factuur informatie"
            }
        },
        hasInvoiceReference() {
            return this.invoiceDTO?.invoiceReference?.invoiceId
        },
        getPDfSource() {
            if (this.getSASKey && this.invoiceDTO.blobStorageUrl) {
                return this.invoiceDTO.blobStorageUrl + '?timeStopCache=' + Date.now() + '&' + this.getSASKey
            }
            return null
        },
        getSASKey() {
            return this.$store.getters["fileExportModule/getSASKey"] || this.sasKey
        },
        showDeleteButton() {
            return this.invoiceDTO?.statusType == InvoiceStatusType.DRAFT
        },
        togglePaidIcon() {
            return this.invoiceDTO?.statusType != InvoiceStatusType.PAID ? 'mdi-credit-card-check-outline' : 'mdi-credit-card-clock-outline'
        },
        togglePaidText() {
            return this.invoiceDTO?.statusType != InvoiceStatusType.PAID ? 'Zet status op betaald' : 'Zet status op open'
        },
        showTogglePaidButton() {
            return this.invoiceDTO?.statusType != InvoiceStatusType.DRAFT
        },
        showCreditNoteButton() {
            return this.invoiceDTO?.statusType != InvoiceStatusType.DRAFT && !this.invoiceDTO?.credit && !this.hasInvoiceReference
        },
        showInvoiceReferenceButton() {
            return  this.hasInvoiceReference
        },        
        showExpirationTime() {
            return !this.invoiceDTO?.credit
        }
    }
}
</script>
<style>
.vue-pdf-embed canvas {
    width: 100% !important;
    height: auto !important;
}
</style>